import React, { useEffect, useMemo, useRef } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Project from "components/project"
import { projects } from "models/experience"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Work = ({ location }) => {
  const targetEl = useRef(null)
  const intl = useIntl()

  useEffect(() => {
    if (targetEl.current) {
      setTimeout(() => {
        targetEl.current.scrollIntoView({
          behavior: `smooth`,
          block: `center`,
        })
      }, 100)
    }
  }, [])

  const { images } = useStaticQuery(graphql`
    query imgQuery {
      images: allFile(
        filter: {
          relativeDirectory: { eq: "project-icons" }
          extension: { regex: "/(jpg)|(png)/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const imageMap = useMemo(() => {
    return images.edges.reduce((map, edge) => {
      const { name, childImageSharp } = edge.node
      map[name] = childImageSharp.fixed
      return map
    }, {})
  }, [images])

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: `page.work` })} />
      <Container>
        {projects.map((p) => (
          <Project
            key={p.id}
            data={p}
            imgData={imageMap[p.iconName]}
            ref={
              location.state && p.id === location.state.target ? targetEl : null
            }
          />
        ))}
      </Container>
    </Layout>
  )
}
export default Work

const Container = styled.div({
  background: `none`,
  paddingTop: `5rem`,
})
