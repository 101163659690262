import React, { forwardRef } from "react"
import styled from "@emotion/styled"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import LinkIcon from "assets/icons/sosa/adieresis_Sosa-Regular.svg"
import { HEADER_BORDER } from "static/global-css"

const Project = forwardRef(({ data, imgData, ...rest }, ref) => {
  return (
    <Container {...rest} ref={ref}>
      <ImgWrapper>
        <IconWrapper>
          {imgData && (
            <Img
              imgStyle={{ objectFit: "contain" }}
              style={{ width: `100%`, height: `100%` }}
              fixed={imgData}
            />
          )}
        </IconWrapper>
      </ImgWrapper>
      <Content>
        <h2>{data.title}</h2>
        {data.url && (
          <ExternalLink
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
            {data.urlTitle || data.title}
          </ExternalLink>
        )}
        <h3>{data.stack}</h3>
        <p>
          <FormattedMessage id={data.description} />
        </p>
      </Content>
    </Container>
  )
})

export default Project

const Container = styled.div({
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
  flexWrap: `wrap`,
  background: `none`,
  minHeight: `50vh`,
  maxWidth: 1024,
  margin: `auto`,
  padding: `5%`,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${HEADER_BORDER}`,
  },
})

const Content = styled.div({
  margin: `5%`,
  flex: 1,
  minWidth: 200,
  "> h2": {
    marginBottom: `.5rem`,
  },
  "> h3": {
    marginBottom: `2rem`,
  },
  "> p": {
    marginBottom: `.5rem`,
  },
})

const ExternalLink = styled.a({
  color: `var(--theme-secondary-font)`,
  display: `flex`,
  marginTop: 10,
  marginBottom: `.5rem`,
  "> svg": {
    width: 20,
    height: 20,
    transform: `rotate(90deg)`,
  },
})

const ImgWrapper = styled.div({
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `30%`,
  minWidth: 200,
  margin: `auto`,
})

const IconWrapper = styled.div({
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  height: 200,
  width: 200,
  borderRadius: `20%`,
  overflow: `hidden`,
  background: `white`,
})
